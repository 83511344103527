import React, { useRef } from 'react';
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkMessenger = () => {
    const tawkMessengerRef = useRef();

    return (
        <TawkMessengerReact
            propertyId="67568a912480f5b4f5aa9b30"
            widgetId="1iel0qj0q"
            ref={tawkMessengerRef}
        />
    );
}

export default TawkMessenger