import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ py: 5 }} maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Effective Date: [11/11/2024]
        </Typography>
        <Typography variant="body1" paragraph>
          Global Care Finder (“GCF,” “We,” “Our,” or “Us”) is committed to
          protecting your privacy. This Privacy Policy describes how we collect,
          use, disclose, and safeguard your information when you use our
          website, mobile application, and related services (collectively, the
          “Platform”). Please read this Privacy Policy carefully.
          <br />
          <br />
          This Privacy Policy is meant to help users of our Services (“Users,”
          “you,” or “your”) understand how we treat their personal information.
          <br />
          <br />
          BY USING OR ACCESSING THE SERVICES IN ANY MANNER, YOU ACKNOWLEDGE THAT
          YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY,
          AND YOU HEREBY CONSENT THAT WE WILL COLLECT, USE, AND SHARE YOUR
          INFORMATION IN THE WAYS DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH
          THIS PRIVACY POLICY, YOU MAY NOT USE THE SERVICES. IF YOU USE THE
          SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD), YOU REPRESENT
          THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL TO ACCEPT THIS PRIVACY
          POLICY ON THE INDIVIDUAL’S BEHALF.
          <br />
          <br />
          Any use of the Platform is subject to the Agreement (as the term
          “Agreement” is defined in our Terms of Use, which incorporates this
          Privacy Policy). You may print a copy of this Privacy Policy by
          clicking here.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            1. Scope and Definitions
          </Typography>
          <Typography variant="body1" gutterBottom>
            For purposes of this Privacy Policy
          </Typography>
          <ul>
            <li>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </li>
            <li>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              "control" means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of
              directors or other managing authority.
            </li>
            <li>
              <strong>Business,</strong> for the purpose of CCPA/CPRA, refers to
              the Company as the legal entity that collects Consumers' personal
              information and determines the purposes and means of the
              processing of Consumers' personal information, or on behalf of
              which such information is collected and that alone, or jointly
              with others, determines the purposes and means of the processing
              of consumers' personal information, that does business in the
              State of California.
            </li>
            <li>
              <strong>CCPA</strong> and/or <strong>CPRA</strong> refers to the
              California Consumer Privacy Act (the "CCPA") as amended by the
              California Privacy Rights Act of 2020 (the "CPRA").
            </li>
            <li>
              <strong>Company</strong> (referred to as either "the Company",
              "We", "Us" or "Our" in this Agreement) refers to
              [www.globalcarefinder.com].
            </li>
            <li>
              <strong>Consumer,</strong> for the purpose of the CCPA/CPRA, means
              a natural person who is a California resident. A resident, as
              defined in the law, includes (1) every individual who is in the
              USA for other than a temporary or transitory purpose, and (2)
              every individual who is domiciled in the USA who is outside the
              USA for a temporary or transitory purpose.
            </li>
            <li>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </li>
            <li>
              <strong>Data Controller,</strong> for the purposes of the GDPR
              (General Data Protection Regulation), refers to the Company as the
              legal person which alone or jointly with others determines the
              purposes and means of the processing of Personal Data.
            </li>
            <li>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <strong>Do Not Track (DNT)</strong> is a concept that has been
              promoted by US regulatory authorities, in particular the U.S.
              Federal Trade Commission (FTC), for the Internet industry to
              develop and implement a mechanism for allowing internet users to
              control the tracking of their online activities across websites.
            </li>
            <li>
              <strong>GDPR</strong>refers to EU General Data Protection
              Regulation.
            </li>
            <li>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
              <p>
                For the purposes of GDPR, Personal Data means any information
                relating to You such as a name, an identification number,
                location data, online identifier or to one or more factors
                specific to the physical, physiological, genetic, mental,
                economic, cultural, or social identity.
              </p>
              <p>
                For the purposes of the CCPA/CPRA, Personal Data means any
                information that identifies, relates to, describes, or is
                capable of being associated with, or could reasonably be linked,
                directly or indirectly, with You.
              </p>
            </li>
            <li>
              <strong>Service</strong> refers to any purchase or the Website or
              both.
            </li>
            <li>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used. For the purpose
              of the GDPR, Service Providers are considered Data Processors.
            </li>
            <li>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </li>
            <li>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </li>
            <li>
              <strong>Website</strong> refers to&nbsp;
              <a
                href="https://www.globalcarefinder.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.globalcarefinder.com
              </a>
            </li>
            <li>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
              <p>
                Under GDPR, You can be referred to as the Data Subject or as the
                User as you are the individual using the Service.
              </p>
            </li>
          </ul>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography variant="body1" gutterBottom>
            We collect the following types of information:
          </Typography>

          <Typography variant="h5" gutterBottom>
            a. Personal Information
          </Typography>
          <Typography variant="body1">
            We may collect the following Personal Data when you use our
            services:
          </Typography>
          <ul>
            <li>Full name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Location data (city, country)</li>
            <li>
              Health-related information (only when voluntarily provided and not
              considered PHI)
            </li>
          </ul>

          <Typography variant="h5" gutterBottom>
            b. Non-Personal Information
          </Typography>
          <Typography variant="body1">
            We may also collect non-identifiable data, such as:
          </Typography>
          <ul>
            <li>Device and browser type</li>
            <li>IP address</li>
            <li>Operating system</li>
            <li>Usage data (e.g., pages viewed, time spent on the Platform)</li>
          </ul>
          <Typography variant="h5" gutterBottom>
            c. Sensitive Information and Health Data
          </Typography>
          <Typography variant="body1">
            GCF does not knowingly collect Protected Health Information (PHI)
            and is not considered a “Business Associate” as defined under the
            Health Insurance Portability and Accountability Act (HIPAA). Any
            health-related information voluntarily provided by Users is
            considered general personal information, not PHI. GCF recommends
            consulting directly with healthcare providers about any
            health-related data privacy concerns.
          </Typography>
          <Typography variant="h5" gutterBottom>
            d. Provider Information
          </Typography>
          <Typography variant="body1">
            GCF collects various types of personal data from Providers,
            including identifiers (e.g., name, email, phone number, address),
            demographic data (e.g., gender, age, spoken language), online
            identifiers (e.g., IP address, device type), internet activity
            (e.g., webpage interactions), geolocation data, and other
            identifying information voluntarily provided, such as unique
            identifiers, license information, and professional credentials. This
            information is obtained directly from healthcare providers and may
            be shared with third parties such as service providers, analytics
            partners, ad networks, insurance providers, health information
            exchanges, and other entities authorized by providers to enhance
            service functionality and fulfill business purposes.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            3. How We Collect Information
          </Typography>
          <Typography variant="h5" gutterBottom>
            a. Direct Collection
          </Typography>
          <Typography variant="body1">
            We collect Personal Data when you:
          </Typography>
          <ul>
            <li>Create an account</li>
            <li>Book services through GCF</li>
            <li>Contact us directly</li>
          </ul>
          <Typography variant="h5" gutterBottom>
            b. Automatic Collection
          </Typography>
          <Typography variant="body1">
            GCF collects certain information automatically via cookies and
            similar tracking technologies, which provide data on usage patterns
            and device information.
          </Typography>
          <Typography variant="h5" gutterBottom>
            c. Third-Party Sources
          </Typography>
          <Typography variant="body1">
            We may obtain information from third-party partners, such as
            healthcare providers, analytics providers, and payment processors,
            to support and enhance services.
          </Typography>
          <Typography variant="h5" gutterBottom>
            d. Integrated Services
          </Typography>
          <Typography variant="body1">
            You may have the option to use Integrated Services (e.g., Google or
            Facebook) to access or register for the Platform. When authorized,
            GCF may collect certain information from these third-party services
            (e.g., name, email, profile picture).{" "}
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            4. How We Use Your Information
          </Typography>
          <Typography variant="body1">
            We may process your information for various purposes, including:
          </Typography>
          <ul>
            <li>
              Service Provision: Providing, operating, and improving the
              Platform and related services.
            </li>
            <li>
              To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.
            </li>
            <li>
              Transaction Management: Processing bookings, payments, and
              managing transactions.
            </li>
            <li>
              Communication: Contacting you for customer support, administrative
              notifications, and, if opted-in, promotional updates.
            </li>
            <li>Personalization: Tailoring your experience on the Platform.</li>
            <li>
              Legal Compliance: Ensuring compliance with applicable laws and
              enforcing our Terms of Use.
            </li>
            <li>
              For other purposes: We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.
            </li>
            <Typography variant="body1">
              With your consent, we process your information on the lawful basis
              of contract performance, legitimate interest, legal obligations,
              and where otherwise required.
            </Typography>
          </ul>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            5. Sharing Your Information
          </Typography>
          <Typography variant="body1">
            We do not sell or rent your personal information. However, we may
            share information in these situations:
          </Typography>
          <ul>
            <li>
              With Healthcare Providers: To facilitate appointments, referrals,
              or consultations as Users request.
            </li>
            <li>
              With Service Providers: Including hosting, analytics, payment
              processors, and other third-party providers who perform services
              on our behalf.
            </li>
            <li>
              For Legal Compliance: If required by law or in response to a
              subpoena, court order, or similar legal process.
            </li>
            <li>
              With Consent: If you provide explicit consent to share information
              with a third party.
            </li>
          </ul>
          <Typography variant="body1">
            <p>
              In certain circumstances, we may share your Personal Data with
              select service providers and third parties to support business
              operations and enhance your experience. These may include payment
              processors (e.g., Stripe) for secure transaction handling,
              security, and fraud prevention consultants to protect against
              fraudulent activities, and data storage, hosting, and technology
              providers to maintain our Platform’s functionality. We also work
              with analytics partners to understand how users interact with our
              Platform and ad networks to provide relevant advertisements.
            </p>
            <p>
              We may share your information with healthcare providers to
              facilitate appointments, referrals, and insurance providers for
              eligibility verification. Additionally, if you choose to log in
              through third-party services, such as social media accounts, or
              publicly share information in forums or reviews. This information
              may be visible to others.
            </p>
            <p>
              We may create aggregated and/or de-identified data from the
              Personal Data we collect, including by removing information that
              makes the data personally identifiable to a particular user. We
              may use such aggregated, de-identified, or anonymized data and
              share it with third parties for our lawful business purposes,
              including to analyze, build, and improve the Services and promote
              our business, provided that we will not share such data in a
              manner that could identify you.
            </p>
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            6. Cookies and Tracking Technologies
          </Typography>
          <Typography variant="body1">
            We use cookies and similar tracking technologies to enhance your
            experience on the Platform. Cookies allow us to analyze trends,
            remember preferences, and customize content. We use the following
            types of Cookies:
          </Typography>
          <ul>
            <li>Essential Cookies: Required for core functionality.</li>
            <li>
              Functional Cookies: Enhance Platform functionality and
              personalization.
            </li>
            <li>
              Analytics Cookies: Help us understand Platform usage and improve
              user experience.
            </li>
          </ul>
          <ul>
            <li>
              What are your choices regarding cookies?
              <ul>
                <li>
                  If you'd like to delete cookies or instruct your web browser
                  to delete or refuse cookies, please visit the help pages of
                  your web browser.
                </li>
              </ul>
            </li>
            <li>
              Managing Your Cookie Preferences
              <ul>
                <li>
                  You can control or delete cookies through your browser
                  settings. Most browsers allow you to:
                  <ul>
                    <li>
                      View what cookies are stored on your device and delete
                      them on an individual basis
                    </li>
                    <li>Block third-party cookies</li>
                    <li>Block cookies from particular websites</li>
                    <li>Block all cookies from being set</li>
                    <li>Delete all cookies when you close your browser</li>
                    <i>
                      Please note, however, that if you delete cookies or refuse
                      to accept them, you might not be able to use all of the
                      features we offer, you may not be able to store your
                      preferences, and some of our pages might not display
                      properly.
                    </i>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Changes to our Cookie Policy
              <ul>
                <li>
                  We may update our Cookie Policy from time to time. We advise
                  you to review this page periodically for any changes. By
                  continuing to use our website after any changes, you agree to
                  be bound by the updated policy.
                </li>
              </ul>
            </li>
          </ul>

          <Typography variant="body1">
            <p>
              Our Platform respects Do Not Track (DNT) browser signals to the
              extent feasible. For more information, visit{" "}
              <a
                href="https://www.allaboutdnt.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.allaboutdnt.com
              </a>
              .
            </p>

            <p>
              To manage cookie preferences, you may adjust your browser
              settings. Disabling cookies may affect some Platform features.
            </p>
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            7. Data Security
          </Typography>
          <Typography variant="body1">
            <p>
              We prioritize the security of your Personal Data, implementing
              appropriate physical, technical, organizational, and
              administrative safeguards tailored to the sensitivity of the
              information we process. Our Platform uses TLS (Transport Layer
              Security) technology to encrypt Personal Data in transit, and we
              store data on secure servers in the United States and abroad,
              backed by standard backup and archival systems.
            </p>
            <p>
              While we take these steps to secure your information, no online
              transmission or storage method is completely secure. We recommend
              protecting your account by using strong passwords, limiting device
              access, and signing off after each session. Please note, that we
              cannot guarantee absolute security and, except as required by law,
              are not liable for unauthorized access, loss, or disclosure of
              your data.
            </p>
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            8. Data Retention
          </Typography>
          <Typography variant="body1">
            We retain Personal Data for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy unless a longer retention
            period is required or permitted by law. Data may be retained to
            comply with legal obligations, resolve disputes, or enforce
            agreements.{" "}
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            9. Your Rights
          </Typography>
          <Typography variant="body1">
            If you are located within the European Economic Area (EEA) or in
            other jurisdictions with applicable privacy laws, you may have the
            following rights:{" "}
          </Typography>
          <ul>
            <li>
              Access: Request access to the personal data we hold about you.
            </li>
            <li>
              Rectification: Request correction of any inaccurate or incomplete
              data.
            </li>
            <li>
              Erasure: Request deletion of your personal data, subject to legal
              obligations.
            </li>
            <li>
              Restriction: Request limited processing of your data in certain
              circumstances.
            </li>
            <li>
              Portability: Request a copy of your data in a structured,
              machine-readable format.
            </li>
            <li>
              Objection: Object to the processing of your data for specific
              purposes, including direct marketing.
            </li>
          </ul>
          <Typography variant="body1">
            To exercise these rights, please contact us using the information
            provided below. we respond without undue delay, and as required by
            law.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            10. Your Rights under the GDPR
          </Typography>
          <Typography variant="body1">
            <p>
              The Company undertakes to respect the confidentiality of Your
              Personal Data and to guarantee You can exercise Your rights.
            </p>
            <p>
              You have the right under this Privacy Policy, and by law if You
              are within the EU, to:
            </p>
          </Typography>
          <ul>
            <li>
              <strong>Request access to Your Personal Data.</strong> The right
              to access, update or delete the information We have on You.
              Whenever made possible, you can access, update or request deletion
              of Your Personal Data directly within Your account settings
              section. If you are unable to perform these actions yourself,
              please contact Us to assist You. This also enables You to receive
              a copy of the Personal Data We hold about You.
            </li>
            <li>
              <strong>
                Request correction of the Personal Data that We hold about You.
              </strong>
              You have the right to have any incomplete or inaccurate
              information We hold about You corrected.
            </li>
            <li>
              <strong>Object to processing of Your Personal Data.</strong> This
              right exists where We are relying on a legitimate interest as the
              legal basis for Our processing and there is something about Your
              particular situation, which makes You want to object to our
              processing of Your Personal Data on this ground. You also have the
              right to object where We are processing Your Personal Data for
              direct marketing purposes.
            </li>
            <li>
              <strong>Request erasure of Your Personal Data.</strong> You have
              the right to ask Us to delete or remove Personal Data when there
              is no good reason for Us to continue processing it.
            </li>
            <li>
              <strong>Request the transfer of Your Personal Data.</strong> We
              will provide to You, or to a third-party You have chosen, Your
              Personal Data in a structured, commonly used, machine-readable
              format. Please note that this right only applies to automated
              information which You initially provided consent for Us to use or
              where We used the information to perform a contract with You.
            </li>
            <li>
              <strong>Withdraw Your consent.</strong> You have the right to
              withdraw Your consent on using your Personal Data. If You withdraw
              Your consent, We may not be able to provide You with access to
              certain specific functionalities of the Service.
            </li>
          </ul>
          <Typography variant="body1">
            <strong>Exercising of Your GDPR Data Protection Rights</strong>
            <p>
              You may exercise Your rights of access, rectification,
              cancellation and opposition by contacting Us. Please note that we
              may ask You to verify Your identity before responding to such
              requests. If You make a request, We will try our best to respond
              to You as soon as possible. You have the right to complain to a
              Data Protection Authority about Our collection and use of Your
              Personal Data. For more information, if You are in the European
              Economic Area (EEA), please contact Your local data protection
              authority in the EEA.
            </p>
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            11. California and Other State-Specific Rights
          </Typography>
          <Typography variant="body1">
            <p>
              If you are a resident of California or another U.S. state with
              comprehensive consumer privacy laws, you may have specific rights
              regarding your Personal Data. These laws generally allow you more
              control over how companies acquire, use, and share your Personal
              Data, which may include the right to request information,
              corrections, or deletions and the ability to opt out of specific
              data uses.
            </p>
            <p>
              California residents have specific rights under the California
              Consumer Privacy Act (CCPA) and the California Privacy Rights Act
              (CPRA), which include:
            </p>
          </Typography>
          <ul>
            <li>
              Right to Know: Access specific information about our data
              practices, including the categories and specific pieces of
              personal information we collect.
            </li>
            <li>
              Right to Delete: Request the deletion of personal information we
              have collected, subject to certain exceptions.
            </li>
            <li>
              Right to Opt-Out of Sale or Sharing: Direct us not to sell or
              share your personal information with third parties for
              cross-context behavioral advertising.
            </li>
            <li>
              Right to Correct: Request correction of inaccurate personal
              information.
            </li>
            <li>
              Right to Non-Discrimination: Not be discriminated against for
              exercising any of these rights.
            </li>
          </ul>
          <Typography variant="body1">
            Residents of the following states have similar consumer privacy
            rights under state-specific laws, which may include rights to
            access, correct, delete, or restrict the use of personal
            information, as well as opt-out rights for certain processing
            activities:{" "}
            <i>
              Colorado, Connecticut, Delaware, Indiana, Iowa, Kentucky,
              Maryland, Minnesota, Montana, Nebraska, New Hampshire, New Jersey,
              Oregon, Rhode Island, Tennessee, Texas, Utah, Virginia, and
              Washington.
            </i>{" "}
            Residents of these states have privacy rights similar to those in
            California, with state-specific variations on data access, deletion,
            portability, and restriction. If you are a resident of one of these
            states, you may also have the right to opt out of targeted
            advertising, profiling, or the sale of personal data. we encourage
            you to contact us if you have questions about specific rights
            available under your state's privacy laws.
          </Typography>
          <Typography variant="body1">
            To exercise any of the above rights, please contact us at
            support@globalcarefinder.com and include “State Privacy Rights” in
            the subject line. When making a request, please provide sufficient
            information for us to verify your identity and process your request.
            we will respond to verifiable requests within the timeframes
            required by applicable law.
          </Typography>
          <Typography variant="body1">
            If we process your Personal Data as a service provider (e.g., on
            behalf of a healthcare provider), please direct your request to the
            entity with which you originally shared it. Certain rights may be
            subject to limitations or exceptions under state law, allowing us to
            deny specific requests.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            12. Children's Privacy
          </Typography>
          <Typography variant="body1">
            The Platform is not directed at children under 13, and we do not
            knowingly collect information from children under this age. If we
            learn that we have inadvertently collected such information, we will
            delete it without undue delay and as soon as practicably possible.
            Parents or guardians who believe we have unintentionally collected
            data from their child should contact us immediately.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            13. International Data Transfers
          </Typography>
          <Typography variant="body1">
            Personal data may be transferred to countries outside your country
            of residence, including countries with different data protection
            laws. When transferring data outside the EEA, we ensure appropriate
            safeguards, such as standard contractual clauses approved by
            regulatory authorities, are in place.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            14. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1">
            We may update this Privacy Policy periodically to reflect changes in
            our practices or legal requirements. The “Effective Date” will be
            updated accordingly. Your continued use of the Platform following
            any changes indicates acceptance of the revised Privacy Policy.{" "}
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            15. Contact Information
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal information, please contact us at:
            <br />
            Email: support@globalcarefinder.com
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" onClick={() => navigate("/sign-up")}>
        Continue to SignUp
      </Button>
    </Container>
  );
};

export default PrivacyPolicy;
