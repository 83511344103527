import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const MainLayout = () => {
  const [footerLoaded, setFooterLoaded] = useState(false);

  useEffect(() => {
    // const timer = setTimeout(() => {
      setFooterLoaded(true);
    // }, 2000);

    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box className="app-layout" display="flex" flexDirection="column" justifyContent={"space-between"}>
        <Box>
          <Header />
        </Box>
        <Box component="section" className="main-body" flex="1" position={"relative "} minHeight="100vh">
          <Outlet />
        </Box>
        <Box>
          {footerLoaded && <Footer />}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
