import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

export default function HalfRating({readOnly, value, setRating}) {
  const rating = (event) => {
    setRating(+event.target.value)
  }
  return (
    <Stack spacing={1}>
      <Rating name="half-rating" defaultValue={value} precision={0.5} readOnly={readOnly} onChange={rating} />
    </Stack>
  );
}