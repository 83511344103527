import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const TermsCondition = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ py: 5 }} maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" gutterBottom>
          Last updated: [10/24/2024]
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to <strong>Global Care Finder</strong> ("we," "us," "our," or
          the "Company"). These Terms and Conditions ("Terms") govern your use
          of our website <strong>www.globalcarefinder.com</strong> (the "Site"),
          and the purchase of products from our platform. By accessing,
          browsing, or using our Site, you ("Customer," "you," or "your") agree
          to comply with and be bound by these Terms. If you do not agree to
          these Terms, please refrain from using our Site.
        </Typography>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1">
            By accessing and using the Global Care Finder platform, you agree to
            comply with these Terms and Conditions, as well as all applicable
            laws and regulations. If you do not agree with any of these terms,
            you are prohibited from using or accessing this platform.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            2. Eligibility
          </Typography>
          <Typography variant="body1">
            Global Care Finder is intended for individuals who are at least 18
            years old. By using our platform, you represent that you are of
            legal age to form a binding contract and meet the eligibility
            requirements. If you are under 18, you must have permission from a
            parent or legal guardian to use the platform.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            3. Platform Purpose
          </Typography>
          <Typography variant="body1">
            Global Care Finder connects individuals with healthcare providers
            globally. We act as a facilitator between users and providers and do
            not provide medical advice, diagnosis, or treatment. The healthcare
            professionals listed on the platform are independent third parties,
            and Global Care Finder is not responsible for the quality or
            outcomes of services provided by them.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            4. User Responsibilities
          </Typography>
          <Typography variant="body1">
            When using Global Care Finder, you agree to:
          </Typography>
          <ul>
            <li>Provide accurate, current, and complete information.</li>
            <li>Use the platform only for lawful purposes.</li>
            <li>
              Refrain from using the platform to harm, harass, or exploit
              others.
            </li>
            <li>
              Avoid posting or transmitting any content that is illegal,
              harmful, or violates the rights of others.
            </li>
          </ul>
          <Typography variant="body1">
            You are solely responsible for maintaining the confidentiality of
            your account login details and for all activities conducted under
            your account.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            5. Healthcare Providers
          </Typography>
          <Typography variant="body1">
            Global Care Finder does not verify the credentials, qualifications,
            or experience of healthcare providers listed on the platform. We
            provide information and tools to connect you with providers, but the
            decision to use a provider is entirely yours. You acknowledge that
            Global Care Finder is not liable for any actions, advice, or
            treatment provided by healthcare professionals.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            6. Payments and Fees
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.1. Pricing</strong>
            <br />
            Prices displayed on the Site are quoted in US Dollars and exclude
            applicable taxes and shipping fees unless otherwise stated. All
            prices are subject to change without prior notice.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.2. Payment Terms</strong>
            <br />
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made through our Site.
            Payments must be made via one of the available methods specified on
            the Site. We reserve the right to cancel or refuse any order due to
            unauthorized, fraudulent, or illegal activity.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>6.3. Billing Information</strong>
            <br />
            We may request additional information to validate payment
            transactions. If we are unable to verify or process your payment, we
            reserve the right to cancel your order.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            7. Cancellations and Refunds
          </Typography>
          <Typography variant="body1">
            Consultation cancellations and refunds are subject to the individual
            healthcare provider’s policy. It is your responsibility to review
            these terms before booking any appointments. Global Care Finder is
            not responsible for refunds or disputes between users and healthcare
            providers.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            8. Privacy Policy
          </Typography>
          <Typography variant="body1">
            Your privacy is important to us. By using Global Care Finder, you
            agree to our collection and use of personal information as outlined
            in our{" "}
            <Typography variant="body1" component={Link} to={"/privacy-policy"}>
              Privacy Policy.
            </Typography>{" "}
            This includes the collection of your name, contact information, and
            health-related data as necessary to provide services.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the maximum extent permitted by law, Global Care Finder is not
            liable for any damages or losses arising from:
          </Typography>
          <ul>
            <li>The use or inability to use the platform.</li>
            <li>
              The reliance on any information or services provided through the
              platform.
            </li>
            <li>
              Any actions or advice of healthcare providers connected through
              the platform.
            </li>
          </ul>
          <Typography variant="body1" paragraph>
            Global Care Finder does not guarantee the accuracy, reliability, or
            completeness of the information provided by users or healthcare
            professionals. All information and services are provided "as is"
            without warranties of any kind, whether express or implied,
            including but not limited to implied warranties of merchantability,
            fitness for a particular purpose, or non-infringement.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.1. No Warranty</strong>
            <br />
            We make no warranties, express or implied, regarding the products or
            services offered on this platform. Global Care Finder disclaims all
            liability for any issues, errors, or disputes arising from the use
            of the platform or any interactions facilitated through it.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.2. Limitation of Liability</strong>
            <br />
            To the maximum extent permitted by law, Global Care Finder is not
            liable for any direct, indirect, incidental, punitive, or
            consequential damages, including but not limited to:
          </Typography>
          <ul>
            <li>Loss of data, revenue, or profits.</li>
            <li>
              Claims related to unauthorized access to or alteration of your
              transmissions or data.
            </li>
            <li>
              Any other damages arising from your use of the platform or
              services provided.
            </li>
          </ul>
          <Typography variant="body1" paragraph>
            This limitation applies even if we have been advised of the
            possibility of such damages.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>9.3. Indemnification</strong>
            <br />
            You agree to indemnify, defend, and hold harmless Global Care
            Finder, its affiliates, officers, directors, agents, employees, and
            suppliers from any claims, liabilities, damages, and expenses
            (including reasonable attorneys’ fees) arising from:
          </Typography>
          <ul>
            <li>Your use of the platform.</li>
            <li>Violation of these Terms and Conditions.</li>
            <li>Any unlawful activity or misuse of your account.</li>
          </ul>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            10. Termination of Service
          </Typography>
          <Typography variant="body1">
            We reserve the right to suspend or terminate your access to Global
            Care Finder at any time for any reason, including violations of
            these Terms and Conditions or any other legal or regulatory
            obligations.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            11. Changes to the Terms
          </Typography>
          <Typography variant="body1">
            Global Care Finder may update these Terms and Conditions from time
            to time. Any changes will be posted on this page, and the "Last
            Updated" date will be revised. Continued use of the platform after
            such changes constitutes your acceptance of the updated terms.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            12. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>12.1. Ownership</strong>
            <br />
            All content, logos, trademarks, and intellectual property displayed
            on the Site are owned by or licensed to Global Care Finder. You may
            not use any such intellectual property without our prior written
            permission.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>12.2. License to Use the Site</strong>
            <br />
            We grant you a limited, non-exclusive, non-transferable, revocable
            license to access and use the Site for your personal, non-commercial
            use, in accordance with these Terms. Unauthorized use of any content
            from the Site may violate copyright, trademark, and other laws.
          </Typography>
        </Box>

        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            13. Governing Law
          </Typography>
          <Typography variant="body1">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of the United States and the State of
            Illinois. Any disputes arising out of these terms will be subject to
            the exclusive jurisdiction of the courts in Wilmington, Illinois.
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" gutterBottom>
            14. Contact Information
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at: Email:
            support@globalcarefinder.com
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" onClick={() => navigate("/sign-up")}>
        {" "}
        Continue to SignUp
      </Button>
    </Container>
  );
};

export default TermsCondition;
