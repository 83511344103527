import { Box, Skeleton } from '@mui/material';
import React from 'react'

const LocationsSkeleton = () => {
    const skeletonNumber = 6;
    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "space-between",
                marginY: "20px",
                padding: "20px"
            }}
        >
            {skeletonNumber > 1 &&
                Array.from({ length: skeletonNumber }).map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="rectangular"
                        width="48%"
                        height={118}
                    />
                ))
            }
        </Box>

    )
}

export default LocationsSkeleton