import React from 'react'

import NotFoundImage from "assets/images/main/not-found.png";
import { Box, Button, Typography, useTheme } from '@mui/material';
import { ROUTES } from "constants";

const NotFound = () => {
    const theme = useTheme();
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center", height: "100vh" }}>
            <Box
                component="img"
                sx={{
                    width: "50%",
                    // maxHeight: { xs: 233, md: 167 },
                    // maxWidth: { xs: 350, md: 250 },
                }}
                alt="Page Not Found"
                src={NotFoundImage}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
                <Typography variant='h1' sx={{
                    [theme.breakpoints.down("md")]: {
                        fontSize: "20px"
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "18px"
                    },
                }}>The page you are looking for could not be found!</Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <Button variant="contained" href={ROUTES.Home}>
                        Go to Home
                    </Button>
                    <Button variant="contained" href={ROUTES.DoctorSearch}>Doctors Home</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default NotFound