
export const RestMethods = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  PATCH: "PATCH",
};

export const PathName = {
  SignUp: "sign-up",
  SignIn: "sign-in",
};

export const ROUTES = {
  Home: "/",
  DoctorSearch: "/doctor-search",
  DoctorProfile: "/doctor-profile/:id",
  DoctorBooking: "/doctor-booking/:id",
  PatientProfile: "/patient-profile",
  ManageProfile: "/manage-profile",
  PatientAppointment: "/patient-appointments",
  SignUp: "/sign-up",
  SignIn: "/sign-in",
  RelevantDoctor: "/users/get-relevent-doctor",
  TermsCondition: "/terms-condition",
  PrivacyPolicy: "/privacy-policy",
  Sitemap: "/sitemap",
  XmlSitemap: "/xml",
  Locations: "/locations",
  ThankYou: "/thank-you"
};

export const ROLE = {
  Doctor: "doctor",
  Patient: "patient",
};

export const REGEX = {
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const PaginationAction = {
  previous: "previous",
  next: "next",
};

export const QueryParams = {
  services: "services",
  specialities: "specialities",
  country: "country",
  state: "state",
  zipCode: "zipCode",
  city: "city",
  doctors: "doctor",
};

export const CASE = {
  services: "service",
  specialities: "speciality",
  doctors: "doctor",
};

export const ApiErrorMessages = {
  signInFail: "Something went wrong: Invalid credentials",
  allDoctorSpecialtiesFailed:
    "Something went wrong: Unable to find doctors specialties",
  paginatedDoctorFailed: "Something went wrong: Unable to find doctors",
  doctorsListFailed: "Something went wrong: Unable to find doctors",
  updatePatientProfile: "Something went wrong: Unable to update your profile",
  postSignUp: "Something went wrong: Unable to register your account",
  postBooking: "Something went wrong: Unable to book your appointment",
  getMyAppointments: "Something went wrong: Unable to fetch your appointments",
  accountDeleted: "Your Account has been deleted by admin",
  getUserData: "Something went wrong: Unable to fetch your record",
  updateAppointmentStatus: "Something went wrong: Unable to update record",
  getAppointmentById: "Something went wrong: Unable to get appointment",
  getSpecialtiesDoctorsServices:
    "Something went wrong: Unable to get Specialties & Services",
  getSpecialityOrDoctorOrService:
    "Something went wrong:  Unable to find Doctors / Specialties / Services",
  featureSpecialities:
    "Something went wrong: Unable to get Feature Specialties",
  getRelevantSpecialities: "Something went wrong: Unable to get Specialties",
  getDoctorTimeSlots: "Something went wrong: Unable to get doctor slots",
  getDoctorsTimeSlots: "Something went wrong: Unable to get doctor slots",
  getSpecialtiesAndServices:
    "Something went wrong: Unable to find Specialties & Services",
  getDoctorReviews: "Something went wrong: unable to fetch reviews",
  userNotFound: "User Not Found"
};

export const ApiSuccessMessages = {
  signInSuccess: "Congratulations: Login success",
  logoutUser: "Logout successfully",
  registerUser: "Registration has been completed successfully",
  resetPassword: "Password Updated successfully"
};
