import MainLayout from "components/layout/MainLayout";
import Loadable from "components/ui/Loadable";
import { ROUTES } from "constants";
import { lazy } from "react";
import TermsCondition from "views/public/terms-condition/TermsCondition";
import PrivacyPolicy from "views/public/privacy-policy/PrivacyPolicy";
import HtmlSitemap from "components/htmlSitemap/HtmlSitemap";
import DoctorsByLocation from "components/ui/DoctorsByLocation/DoctorsByLocation";
import NotFound from "components/ui/NotFound/NotFound";
import ThankYou from "views/private/thank-you/ThankYou";
const DashboardDefault = Loadable(
  lazy(() => import("views/private/dashboard"))
);
const DoctorProfilePage = Loadable(lazy(() => import("views/public/profile")));
const SearchDoctorPage = Loadable(lazy(() => import("views/public/search")));
const ManageProfile = Loadable(
  lazy(() => import("views/private/manage-profile"))
);
const ReviewAndBook = Loadable(lazy(() => import("views/public/booking")));

const MainRoutes = {

  path: ROUTES.Home,
  element: <MainLayout />,

  children: [
    {
      path: ROUTES.Home,
      element: <DashboardDefault />,
    },
    {
      path: ROUTES.DoctorSearch,
      element: <SearchDoctorPage />,
    },
    {
      path: ROUTES.Locations,
      element: <DoctorsByLocation />,
    },
    {
      path: ROUTES.DoctorProfile,
      element: <DoctorProfilePage />,
    },
    {
      path: ROUTES.ManageProfile,
      element: <ManageProfile />,
    },
    {
      path: ROUTES.DoctorBooking,
      element: <ReviewAndBook />,
    },
    {
      path: ROUTES.TermsCondition,
      element: <TermsCondition />
    },
    {
      path: ROUTES.PrivacyPolicy,
      element: <PrivacyPolicy />
    },
    {
      path: ROUTES.Sitemap,
      element: <HtmlSitemap />
    },
    {
      path: "*",
      element: <NotFound />
    },
    {
      path: ROUTES.ThankYou,
      element: <ThankYou />
    }
  ],
};

export default MainRoutes;
