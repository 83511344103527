import {
  Box,
  Grid,
  Link as MuiLink,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import DoctorService from "services/DoctorService";
import { getResponse } from "utils/get-response";
import Structure from "./Structure";
// import Logo from "assets/images/main/logo-white.png";

import LinkedIn from "assets/icons/logo/linkedin.png";
import Instagram from "assets/icons/logo/instagram.png";
import Facebook from "assets/icons/logo/facebook.png";
import Youtube from "assets/icons/logo/youtube.png";
import Twitter from "assets/icons/logo/twitter.png";
import Tiktok from "assets/icons/logo/tiktok.png";

const Footer = () => {
  const [featureSpecialities, setFeatureSpecialities] = useState();
  const featureSpecialitiesApi = useApi(DoctorService.featureSpecialities);
  const navigate = useNavigate();
  const theme = useTheme();

  const fetchFeatureSpecialities = async () => {
    let response = await featureSpecialitiesApi.request();
    response = getResponse(response);
    if (response && response?.length) {
      setFeatureSpecialities(
        response.map((speciality) => {
          return {
            id: speciality.id,
            text: speciality.name,
          };
        })
      );
    } else {
      toast.info("No feature specialty exist");
    }
  };

  useEffect(() => {
    fetchFeatureSpecialities();
  }, []);

  return (
    <Box
      mt={5}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        padding: "2em 3em",
        borderRadius: "10px",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Box component="img" src={"/logo.png"} sx={{ width: "200px" }}></Box>
        </Grid>

        <Grid item xs={12} sm={6} md={5}>

          <Grid item xs={12} sm={6} md={12}>
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: theme.palette.background.paper,
                  textTransform: 'uppercase',
                  letterSpacing: "1.2px"
                }}
              >
                Top Specialties
              </Typography>
              <List>
                {featureSpecialities?.length ? (
                  <Structure data={featureSpecialities} />
                ) : (
                  <ListItem
                    sx={{
                      color: theme.palette.background.paper,
                    }}
                  >
                    No Data found
                  </ListItem>
                )}
              </List>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={1}>

            <Box sx={{ marginTop: "1em" }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: theme.palette.background.paper,
                  textTransform: 'uppercase',
                  letterSpacing: "1.2px"
                }}
              >
                Contact
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.background.paper,
                  padding: "10px 0",
                }}
              >
                <MuiLink
                  href="mailto:service@Global Care Finder.com"
                  sx={{
                    color: theme.palette.background.paper,
                  }}
                >
                  support@globalcarefinder.com
                </MuiLink>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                marginBottom: "15px",
                color: theme.palette.background.paper,
                textTransform: 'uppercase',
                letterSpacing: "1.2px"
              }}
            >
              Connect With Us
            </Typography>
            <Box sx={{
              display: "flex",
              gap: "1em",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
              },
            }}>
              <MuiLink href="">
                <Box component="img" src={LinkedIn}
                  sx={{
                    wwidth: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
              <MuiLink href="">
                <Box component="img" src={Youtube}
                  sx={{
                    width: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
              <MuiLink href="">
                <Box component="img" src={Twitter}
                  sx={{
                    width: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
              <MuiLink target="_blank" href="https://www.instagram.com/globalcarefinder/">
                <Box component="img" src={Instagram}
                  sx={{
                    width: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
              <MuiLink target="_blank" href="https://www.facebook.com/people/Global-Care-Finder/61567352351701/">
                <Box component="img" src={Facebook}
                  sx={{
                    width: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
              <MuiLink href="">
                <Box component="img" src={Tiktok}
                  sx={{
                    width: 35,
                    height: 35,
                    [theme.breakpoints.down("sm")]: {
                      width: 15,
                      height: 15
                    },
                  }}
                />
              </MuiLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 4,
          textAlign: "center",
          borderTop: "2px solid #fff",
          paddingTop: "1.5em",
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            color: theme.palette.background.paper,
          }}
        >
          © 2024 Global Care Finder{" "}
          <Typography
            variant="body2"
            component={Link}
            to={"/terms-condition"}
            sx={{
              color: "white", // Initial color for the text
              fontWeight: "bold", // Make text bold
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
              padding: "4px 8px", // Optional: Padding around text
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Optional: Light background for contrast
              borderRadius: "4px", // Optional: Rounded corners
              cursor: "pointer", // Show pointer on hover
              textDecoration: "none",
              "&:hover": {
                color: "#FFD700", // Change text color on hover (e.g., gold)
                backgroundColor: "rgba(255, 255, 255, 0.3)", // Lighten background on hover
              },
            }}
          >
            Terms & Condition
          </Typography>{" "}
          <Typography
            variant="body2"
            component={Link}
            to={"/privacy-policy"}
            sx={{
              color: "white", // Initial color for the text
              fontWeight: "bold", // Make text bold
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)", // Add text shadow for better visibility
              padding: "4px 8px", // Optional: Padding around text
              backgroundColor: "rgba(255, 255, 255, 0.2)", // Optional: Light background for contrast
              borderRadius: "4px", // Optional: Rounded corners
              cursor: "pointer", // Show pointer on hover,
              textDecoration: "none",
              "&:hover": {
                color: "#FFD700", // Change text color on hover (e.g., gold)
                backgroundColor: "rgba(255, 255, 255, 0.3)", // Lighten background on hover
              },
            }}
          >
            Privacy Policy.
          </Typography>{" "}
        </Typography>
      </Box>
    </Box >
  );
};

export default Footer;