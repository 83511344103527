import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react';
import { ROUTES } from "constants";
import ThankyouImage from "assets/images/main/thank-you.png"

const ThankYou = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                padding: 2,
            }}
        >
            <Box sx={{
                display: "flex", [theme.breakpoints.down("md")]: {
                    flexDirection: "column"
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={ThankyouImage}
                        alt="Illustration"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '10px',
                        }}
                    />
                </Box>

                {/* Text Section */}
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left', ml: 4, [theme.breakpoints.down("md")]: {
                        ml: 0
                    }, maxWidth: 500
                }}>
                    <Typography variant="h2" sx={{ fontSize: 36, fontWeight: 600 }}>
                        Thank You!
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 16, mt: 2, color: '#555' }}>
                        Welcome to Global Care Finder—your trusted partner in health and convenience. Book appointments effortlessly and take control of your well-being with just a few clicks. Your health, your schedule, simplified!
                    </Typography>
                    <Button sx={{ marginTop: "20px" }} variant="contained" href={ROUTES.Home}>
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </Box >
    )
}

export default ThankYou