import { Box, TableCell, TableRow, Typography, Button, useTheme } from "@mui/material";
import NoDataFoundIcon from "assets/icons/no-data.svg";
import { ROUTES } from "constants";

const NoData = ({ isTable, name, colSpanValue }) => {
  const theme = useTheme();
  return isTable ? (
    <TableRow
      sx={{
        backgroundColor: theme.palette.background.paper,
        fontStyle: "italic",
      }}
    >
      <TableCell colSpan={colSpanValue} sx={{ textAlign: "center", width: "100%" }}>
        <Box
          component="img"
          src={NoDataFoundIcon}
          sx={{
            width: "20%",
            height: "20%",
            marginX: "auto",
          }} />
        <Typography
          variant="h6"
          sx={{
            marginBottom: "30px"
          }}
        >
          No {name} to show
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", gap: "10px", textAlign: "center" }}>
      <Box
        component="img"
        src={NoDataFoundIcon}
        sx={{
          width: "40%",
          height: "40%",
          marginX: "auto",
          [theme.breakpoints.down("sm")]: {
            width: "80%",
            height: "30%",
          },
        }} />
      <Typography
        variant="h2"
        sx={{
          marginBottom: "30px"
        }} >
        No {name} found
      </Typography>
      <Button variant="contained" href={ROUTES.Home}>
        Go to Home
      </Button>
    </Box>
  );

};

export default NoData;
