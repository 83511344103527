import React, { useEffect, useState } from 'react';
import { Box, Link, Typography, useTheme, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import NotFound from 'components/ui/NotFound/NotFound';
import SitemapSkeleton from 'components/ui/skeletons/SitemapSkeleton';


const HtmlSitemap = () => {
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [value, setValue] = useState('countries');
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    useEffect(() => {
        const fetchScriptData = async () => {
            try {
                const response = await fetch('/sitemap.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch sitemap data');
                }
                const data = await response.json();
                if (value === 'specialities') {
                    setRoutes(data.specialities || []);
                    setTotalCount(data.specialities.length || 0);

                } else if (value === 'services') {
                    setRoutes(data.services || []);
                    setTotalCount(data.services.length || 0);

                } else if (value === 'doctors') {
                    setRoutes(data.doctors || []);
                    setTotalCount(data.doctors.length || 0);

                } else if (value === 'countries') {
                    setRoutes(data.countries || []);
                    setTotalCount(data.countries.length || 0);

                }

                setLoading(false)

            } catch (error) {
                console.error('Error fetching script data:', error);
                setRoutes([])
                setLoading(false)
                setTotalCount(0)
            }
        };

        fetchScriptData();
    }, [value])

    return (
        <Box sx={{ marginY: "20px", minHeight: "100vh" }}>

            <Typography variant='h1' sx={{ marginBottom: "15px" }}>Global Care Finder Sitemap</Typography>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab value="countries" label="Countries" />
                            <Tab value="doctors" label="Doctors" />
                            <Tab value="specialities" label="Specialities" />
                            <Tab value="services" label="Services" />
                        </TabList>
                    </Box>
                    {!loading
                        ?
                        totalCount > 0
                            ?
                            <TabPanel value={value}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "10px", marginY: "20px" }}>
                                    {routes.map((route, index) => (
                                        <Link sx={{
                                            fontSize: "18px",
                                            width: "20%",
                                            marginBottom: "10px",
                                            [theme.breakpoints.down("sm")]: {
                                                width: "40%",
                                                textAlign: "center"
                                            },
                                        }} key={index} href={route.path}>{route.label}</Link>
                                    ))}
                                </Box>
                            </TabPanel>
                            : <NotFound />
                        : <SitemapSkeleton />
                    }

                </TabContext>
            </Box>

        </Box >
    );
}

export default HtmlSitemap