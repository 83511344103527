// import React from "react";
// import { createRoot } from "react-dom/client";
// import { Provider } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import persistStore from "redux-persist/es/persistStore";
// import { PersistGate } from "redux-persist/integration/react";
// import configureStore from "store";
// import App from "./App";

// const container = document.getElementById("root");

// const root = createRoot(container);
// const store = configureStore();
// const persistor = persistStore(store);


import React from "react";
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "store";
import App from "./App";

const container = document.getElementById("root");

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.hydrate(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <App />
    </PersistGate>
  </Provider>,
  container
);